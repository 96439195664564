import React from 'react'
import userContext from '../userContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import profile from '../Icons/profile.png'


export default function DropDown() {
    const{dropDown,setDropdown,setName,setEmail,setPassword,setUserName,setLinksArr,setBio
        ,setInstagram
        ,setFacebook 
        ,setLinkedin 
        ,setWebsite  
        ,setwhatsapp 
        ,setTwitter  
        ,setSnapchat 
        ,setYoutube  
        ,setTiktok,
        setPicture,
      } = useContext(userContext);
    const nav = useNavigate()

    const dropdownFunction = ()=>
    {
      if(dropDown === 'none')
      {
          setDropdown('flex');
      }
      else
      {
          setDropdown('none')
      }
    }

    const logout = ()=>
  {
    setName(null);
    setEmail(null);
    setPassword(null);
    setUserName(null);
    setLinksArr(null);
    setBio(null);
    setInstagram(null);
    setFacebook(null);
    setLinkedin(null);
    setWebsite (null);
    setwhatsapp(null);
    setTwitter (null);
    setSnapchat(null);
    setYoutube (null);
    setTiktok  (null);
    setPicture(null);
    setDropdown('none');
    nav('/');
  }

  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'end',alignItems:'end',position:'relative'}}>
    <img style={{width:'8%'}} src={profile} onClick={dropdownFunction} alt="profile" />
    <div className='dropDown' style={{display:dropDown}}>
        <button id='button1' onClick={()=>{nav('/profile');setDropdown('none')}}>My IDigital Card</button>
        <button id='button2' onClick={()=>{nav('/profile-edit');setDropdown('none')}}>Edit Profile</button>
        <button id='button3' onClick={logout}>Log Out</button>
    </div>
    </div>
  )
}
