import './App.css';
import {BrowserRouter,Route,Routes} from 'react-router-dom'
import { UsersProvider } from './userContext';
import Home from './Pages/Home';
import {  useState } from 'react';
import MyLinksTreeGet from './Components/MyLinksTreeGet';
import LogInRegister from './Pages/LogInRegister';
import UserLinkChoose from './Pages/UserLinkChoose';
import ForgotPassword from './Pages/ForgotPassword';
import CodeSubmit from './Pages/CodeSubmit';
import ResetPassword from './Pages/ResetPassword';
import Profile from './Pages/Profile';
import ProfileEdit from './Pages/ProfileEdit';

const theName = '';
function App() {
  const [codeName,setCodeName]= useState('')
  const [LinksData,setLinksData] = useState([])

  




  
  return (
    <UsersProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/login' element={<LogInRegister/>}/>
          <Route path='/user-choose' element={<UserLinkChoose/>}/>
          <Route path='/forgotpassword' element={<ForgotPassword/>}/>
          <Route path='/code-submit' element={<CodeSubmit/>}/>
          <Route path='/reset-password' element={<ResetPassword/>}/>
          <Route path='/profile' element={<Profile/>}/>
          <Route path="/profile-edit" element={<ProfileEdit/>}/>
          <Route path={`/:user`} element={<MyLinksTreeGet/>}/>

          {/* <Route path='/Links' element={<LinksInformations/>}/> */}
          {/* <Route path='/MyTree' element={<MyPage codeName={codeName} setCodeName={setCodeName}/>}/> */}
          {/* <Route path={`/${codeName}`} element={<MyLinksTree/>}/> */}
        </Routes>
      </BrowserRouter>
    </UsersProvider>
  );
}

export default App;
