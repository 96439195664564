import React, { useEffect,useState,useContext } from 'react'
import userContext from '../userContext';
import { useNavigate, useParams } from 'react-router-dom';
import idigitalcardlogo from '../images/idigitalcard.png'
import copy from '../Icons/copy.png'
import instagramIcon from '../Icons/instablack.png'
import facebookIcon from '../Icons/facebookBlack.png'
import linkedinIcon from '../Icons/linkedin.png'
import websiteIcon from '../Icons/website.png'
import whatsappIcon from '../Icons/whatsappblack.png'
import tiktokIcon from '../Icons/tiktokblack.png'
import twitterIcon from '../Icons/twitter.png'
import snapchatIcon from '../Icons/snapchat.png'
import youtubeIcon from '../Icons/youtube.png'
import link from '../Icons/link.png'



export default function MyLinksTreeGet() {
    const { user } = useParams();
    const{name,setName,setEmail,LinksArr,setLinksArr,
      setInstagram
       ,setFacebook 
       ,setLinkedin 
        ,setWebsite  
       ,setwhatsapp 
        ,setTwitter  
       ,setSnapchat 
      ,setYoutube  
       ,setTiktok,
      picture,setPicture,
      socialMediaArr,setSocialMediaArr,
      bio,setBio,userName,setUserName } = useContext(userContext);



    const copyToClipboard = async (text) => {
      try {
        await navigator.clipboard.writeText('https://www.idigitalcard.me/' + text);
        alert('Copied to clipboard!');
      } catch (error) {
        console.error('Error copying to clipboard:', error);
        alert('Failed to copy to clipboard!');
      }
  }

  useEffect(() => {
    const fetchData = () => {
      fetch(`/getusername`
      ,{
        headers:{
            "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify({
            username:user
        })
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error('User not found');
          }
          return response.json();
        })
        .then((data) => {
          if (data === 'Error') {
            // Handle the case where the username is not found
            console.error('User not found');
          } else {
            setName(data.name);
            setEmail(data.email);
            setUserName(data.username);
            setBio(data.bio);
            setInstagram(data.instagram);
            setFacebook(data.facebook);
            setLinkedin(data.linkedin);
            setWebsite(data.website);
            setwhatsapp(data.whatsapp);
            setTiktok(data.tiktok);
            setTwitter(data.twitter);
            setSnapchat(data.snapchat);
            setYoutube(data.youtube);
            setLinksArr(data.Links);
            if (data.picture) {
                resizeImage(data.picture).then((resizedImage) => {
                  setPicture(resizedImage);
                });
              }
              setSocialMediaArr([
                {type : 'instagram' ,link : data.instagram ,icon : instagramIcon},
                {type :'facebook', link :   data.facebook     ,icon : facebookIcon},
                {type : 'linkedin', link :  data.linkedin    ,icon : linkedinIcon},
                {type : 'website' , link:   data.website      ,icon : websiteIcon},
                {type:'whatsapp' ,link :    data.whatsapp      ,icon : whatsappIcon},
                {type: 'twitter' ,link:     data.twitter       ,icon : twitterIcon},
                {type : 'snapchat' ,link :  data.snapchat   ,icon : snapchatIcon},
                {type : 'youtube' , link :  data.youtube    ,icon : youtubeIcon},
                {type :'tiktok' , link :    data.tiktok       ,icon : tiktokIcon}
                ])
          }
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    };

    fetchData();
  }, []);

    const showSocialMediaFunc = ()=>
    {
      return socialMediaArr.map((value)=>
      {
        if(value.link !== '' && value.type !== 'whatsapp')
        {
          return <a href={value.link} rel="noreferrer" target='_blank'><img src={value.icon} alt={value.type}/></a>
        }
        else if(value.type === 'whatsapp' && value.link !== '')
        {
          return <a href={`https://wa.me/${value.link}`} rel="noreferrer" target='_blank'><img src={value.icon} alt={value.type}/></a>
        }
      })

    }




    const showLinks = ()=>
    {
      return LinksArr.map((value)=>
      {
        return <a href={value.link} rel="noreferrer" target='_blank'>
        <img src={link} style={{width:'20%'}} alt="link-pic" />
      <div>
          <h3>{value.title}</h3>
          <h3 style={{color:'#494949',fontSize:'15px'}}>{value.link}</h3>
      </div>
    </a>
      })
    }

  const imageShow = ()=>
    {
        if(picture === null)
        {
            return <div className='imageDiv'>
            <img src={idigitalcardlogo} alt="selected" style={{alignSelf:'center' }} />
            <div className='blurDiv'>
              {showSocialMediaFunc()}
            </div>
        </div>
        }
        else
        { 
            return <div className='imageDiv'>
                <img src={picture} alt="selected" style={{alignSelf:'center' }} />
                <div className='blurDiv'>
                  {showSocialMediaFunc()}
                </div>
            </div>
        }
    }


        // Function to resize the image
  const resizeImage = (imageData) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageData;
      img.onload = () => {
        // Set the canvas dimensions to the desired size
        const maxWidth = 800; // Max width
        const maxHeight = 600; // Max height
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions
        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;
          if (width > height) {
            width = maxWidth;
            height = width / aspectRatio;
          } else {
            height = maxHeight;
            width = height * aspectRatio;
          }
        }

        // Create a canvas element
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw the image onto the canvas with the new dimensions
        ctx.drawImage(img, 0, 0, width, height);

        // Convert canvas content to base64 encoded JPEG with specified quality
        const compressedPicture = canvas.toDataURL('image/jpeg', 1); // Adjust quality as needed

        // Resolve the promise with the resized image data
        resolve(compressedPicture);
      };
    });
  };





    return (
      <div className='profile-container'>
      <header className='profile-container-header'>
          {imageShow()}
      </header>
      <div className='profile-content'>
          <h1>
              {name}
          </h1>
          <div  className='copy-link-button-div'>
              <h3>@{userName}</h3>
              <img src={copy} onClick={()=>{copyToClipboard(userName)}} alt="copy" />
          </div>
          <p>{bio}</p>
      </div>
      <div className='links-show-div'>
            {showLinks()}
        </div>
      <footer className='idigitalcardFooter'>
      <img src={idigitalcardlogo} alt="idigitalcardlogo" />
      </footer>
      
  </div>
      );
    };

