import React from 'react'
import idigitalcardlogo from '../images/idigitalcard.png'
import userContext from '../userContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CodeSubmit() {
    const{
        resetPasswordCode,resievedCode,setResievedCode} = useContext(userContext);
    const nav = useNavigate()


    const codeSubmit = ()=>
    {
        if(resetPasswordCode != resievedCode)
        {
            alert("Please enter the correct verification code!")
        }
        else
        {
            nav('/reset-password')
        }
    }


  return (
    <div className='forgot-password-container'>
        <div className='idigitalcardlogoDiv'>
          <img src={idigitalcardlogo} alt="idigitalcardlogo" />
        </div>
        <h1>
            RESET YOUR PASSWORD
        </h1>
        <div  className='inputemailforgotpassworddiv'>
            <label  id='email' >
              Enter The Code Sent To Your Email:
            <input name='code' value={resievedCode} autoComplete='code' onChange={(e)=>{setResievedCode(e.target.value)}} type="text" />
            </label>
        <button className='SENDCODEBUTTON' onClick={codeSubmit}>Submit Code</button>
        </div>
    </div>
  )
}
