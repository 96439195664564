import {useState,createContext} from 'react'



const userContext = createContext()

export const UsersProvider =({children})=>
{
    const[flag,setFlag] = useState(true)
    const[email,setEmail] = useState('')
    const[accountOrLinks,setAccountOrLinks] = useState('account')
    const[password,setPassword] = useState('')
    const[submitPasswrod,setSubmitPasswrod] = useState('')
    const[resetPasswordCode,setResetPasswordCode] = useState('')
    const[resievedCode,setResievedCode] = useState('')
    const[dropDown,setDropdown] = useState('none');
    const[codePage,setCodePage] = useState('') 
    const[LinksData,setLinksData] = useState([])
    const[file,setFile] = useState(null);

    const[picture,setPicture] = useState(null);
    const[userName,setUserName] = useState('')
    const[name,setName] = useState('')
    const[bio,setBio] = useState('')
    const[instagram,setInstagram] = useState('')
    const[facebook ,setFacebook ] = useState('')
    const[linkedin ,setLinkedin ] = useState('')
    const[website  ,setWebsite  ] = useState('')
    const[whatsapp ,setwhatsapp ] = useState('')
    const[twitter  ,setTwitter  ] = useState('')
    const[snapchat ,setSnapchat ] = useState('')
    const[youtube  ,setYoutube  ] = useState('')
    const[tiktok   ,setTiktok   ] = useState('')
    const[LinksArr,setLinksArr] = useState([]);
    const[socialMediaArr,setSocialMediaArr] = useState([])

    
    return(
        <userContext.Provider value={{LinksData,setLinksData,codePage,
        setCodePage,flag,setFlag,LinksArr,setLinksArr,name,setName,email,setEmail,password,setPassword,
        userName,setUserName,resetPasswordCode,setResetPasswordCode,resievedCode,setResievedCode,
        submitPasswrod,setSubmitPasswrod,accountOrLinks,setAccountOrLinks,dropDown,setDropdown,
        bio,setBio,
        instagram,setInstagram
        ,facebook ,setFacebook 
        ,linkedin ,setLinkedin 
        ,website  ,setWebsite  
        ,whatsapp ,setwhatsapp 
        ,twitter  ,setTwitter  
        ,snapchat ,setSnapchat 
        ,youtube  ,setYoutube  
        ,tiktok   ,setTiktok ,
        picture,setPicture  ,
        file,setFile,
        socialMediaArr,setSocialMediaArr
        }}>
            {children}
        </userContext.Provider>
    )
}

export default userContext
