import React, { } from 'react'
import { useNavigate } from 'react-router-dom'
import idigitalcardlogo from '../images/idigitalcard.png'
import mainpicture from '../images/mainpicture.png'





export default function Home() {
    const nav = useNavigate()
  return (
    <div className='HomeComponent'>
        <img src={idigitalcardlogo} alt="idigitalcard" />
          <p>
            Your ID Digital Card In One Link! 
          </p>
        <img src={mainpicture} alt="mainpicture" />
        <button onClick={()=>{nav('/login')}}>GET STARTED!</button>

        <img style={{width:'40%',marginTop:'20%'}} src={idigitalcardlogo} alt="idigitalcard" />



    </div>
  )
}
