import React, { useEffect, useState } from 'react'
import idigitalcardlogo from '../images/idigitalcard.png'
import userContext from '../userContext'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import copy from '../Icons/copy.png'
import link from '../Icons/link.png'


export default function Profile() {

    const{name,LinksArr,
        picture} = useContext(userContext);
    const{userName,socialMediaArr,bio} = useContext(userContext);
    const[showSocialMedia,setShowSocialMedia] = useState([])

    const nav = useNavigate()


    const copyToClipboard = async (text) => {
        try {
          await navigator.clipboard.writeText('https://www.idigitalcard.me/' + text);
          alert('Copied to clipboard!');
        } catch (error) {
          console.error('Error copying to clipboard:', error);
          alert('Failed to copy to clipboard!');
        }
    }
    




    const socialMedia = ()=>
    {
      const firstTemp = socialMediaArr.filter((value, index, array) => 
      {
        if(value.link !== '')
        {
          return value
        }
      })
      setShowSocialMedia(firstTemp)
    }

    useEffect(()=>
    {
        socialMedia()
    },[])

    const showSocialMediaFunc = ()=>
    {
      return socialMediaArr.map((value)=>
      {
        if(value.link !== '' && value.type !== 'whatsapp')
        {
          return <a href={value.link} rel="noreferrer" target='_blank'><img src={value.icon} alt={value.type}/></a>
        }
        else if(value.type === 'whatsapp' && value.link !== '')
        {
          return <a href={`https://wa.me/${value.link}`} rel="noreferrer" target='_blank'><img src={value.icon} alt={value.type}/></a>
        }
      })
    }



    const showLinks = ()=>
    {
      return LinksArr.map((value)=>
      {
          return <a href={value.link} rel="noreferrer" target='_blank'>
              <img src={link} style={{width:'20%'}} alt="link-pic" />
            <div>
                <h3>{value.title}</h3>
                <h3 style={{color:'#494949',fontSize:'15px'}}>{value.link}</h3>
            </div>
          </a>
      })
    }

    

    const imageShow = ()=>
    {
        if(picture === null)
        {
            return <div className='imageDiv'>
            <img src={idigitalcardlogo} alt="selected" style={{alignSelf:'center' }} />
            <div className='blurDiv'>
                {showSocialMediaFunc()}
            </div>
        </div>
        }
        else
        {

            
            return <div className='imageDiv'>
                <img src={picture} alt="selected" style={{alignSelf:'center' }} />
                <div className='blurDiv'>

                {showSocialMediaFunc()}
                </div>
            </div>
        }
    }


    
    

  return (
    <div className='profile-container'>
        <header className='profile-container-header'>
            {imageShow()}
        </header>
        <div className='profile-content'>
            <h1>
                {name}
            </h1>
            <div  className='copy-link-button-div'>
                <h3>@{userName}</h3>
                <img src={copy} onClick={()=>{copyToClipboard(userName)}} alt="copy" />
            </div>
            <p>{bio}</p>
        </div>
        <div className='links-show-div'>
            {showLinks()}
        </div>
        <div className='profile-edit-button'>
            <h2 onClick={()=>{nav('/profile-edit')}}>Edit Profile..</h2>
        </div>

        <footer className='idigitalcardFooter'>
        <img src={idigitalcardlogo} alt="idigitalcardlogo" />
        </footer>
        
    </div>
  )
}
