import React from 'react'
import idigitalcardlogo from '../images/idigitalcard.png'
import userContext from '../userContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';


export default function ResetPassword() {

    const{email,password,setPassword,
        submitPasswrod,setSubmitPasswrod} = useContext(userContext);
    const nav = useNavigate()

    const submitPassword = ()=>
    {
        if(password !== submitPasswrod)
        {
            alert("Passwords do not match")
        }
        else
        {
            fetch('/reset-password',
            {
                headers:{
                    "Content-Type":"application/json",           
                },
                method:'post',
                body:JSON.stringify({
                    email:email.toLowerCase(),
                    password:password,
                })
            }).then((res)=>{return res.json()})
            .then((data)=>{
                if(data === 'Please Try Again!')
                {
                    alert('Error in Submission, Please Try Again Later!');
                }
                else if(data === 'Password update failed')
                {
                    alert(data)
                }
                else
                {
                    alert("Password updated successfully, You will recieve an Email soon, you can login Now!");
                    nav('/login');
                }
            }).catch((e)=>{return e})
        }
    }

  return (
    <div className='forgot-password-container'>
        <div className='idigitalcardlogoDiv'>
          <img src={idigitalcardlogo} alt="idigitalcardlogo" />
        </div>
        <h1>
            RESET YOUR PASSWORD
        </h1>
        <div  className='inputemailforgotpassworddiv'>
            <label  id='new-password' >
              Enter Your New Password:
              <input name='new-password' value={password} autoComplete='new-password' onChange={(e)=>{setPassword(e.target.value)}} type="text" />
            </label>
            <label  id='submit-password' >
              Submit Your New Password:
              <input name='submit-password' value={submitPasswrod} autoComplete='submit-Password' onChange={(e)=>{setSubmitPasswrod(e.target.value)}} type="text" />
            </label>

        <button className='CHANGEPASSWORDBUTTON' onClick={submitPassword}>CHANGE PASSWORD</button>
        </div>
    </div>
  )
}
