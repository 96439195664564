import React, { useContext  } from 'react'
import idigitalcardlogo from '../images/idigitalcard.png'
import userContext from '../userContext'
import { useNavigate } from 'react-router-dom';

export default function UserLinkChoose() {
    const{userName,setUserName} = useContext(userContext);
    const{setName,email,setEmail,setPassword,setBio,setLinksArr,
         setInstagram
         ,setFacebook 
         ,setLinkedin 
         ,setWebsite  
         ,setwhatsapp 
         ,setTwitter  
         ,setSnapchat 
         ,setYoutube  
         ,setTiktok,
        setPicture,
     } = useContext(userContext);
    const nav = useNavigate()

    const updateAccount = ()=>
    {
        if(userName.length < 5)
        {
            alert("Username must be at least 5 characters long")
        }else
        {

            fetch('/update-user-name',{
                headers:{
                    "Content-Type":"application/json",           
                },
                method:'post',
                body:JSON.stringify({
                    email:email.toLowerCase(),
                    username:userName.toLowerCase()
                })
            })
            .then((res)=>{return res.json()})
            .then((data)=>
            {
                if(data === 'User Did Not Updated! Please Try Again')
                {
                    alert('User Did Not Updated! Please Try Again');
                }
                else if(data === 'Username Already Exists!')
                {
                    alert('Username Already Exists! Please Choose Another One.')
                }
                else
                {
                    setName(data.name);
                    setEmail(data.email);
                    setPassword(data.password);
                    setUserName(data.username);
                    setBio(data.bio);
                    setInstagram(data.instagram)
                    setFacebook(data.facebook) 
                    setLinkedin(data.linkedin) 
                    setWebsite(data.website)  
                    setwhatsapp(data.whatsapp)    
                    setTwitter(data.twitter)          
                    setSnapchat(data.snapchat)      
                    setYoutube(data.youtube)       
                    setTiktok(data.tiktok)
                    setPicture(data.picture)
                    setLinksArr(data.Links);
                    nav('/profile');
                }
            }).catch((e)=>{return e})
        
        }
    }
    
  return (
    <div className='UserLinkChooseContainer'>
        <header className='idigitalcardHeader'>
            <img src={idigitalcardlogo} alt="idigitalcardlogo" />
        </header>
        <label  id='username-input' >
          Your username
          <div className='username-inputDiv'>
            <h3>idigitalcard.me/</h3>
            <input value={userName} name='username' autoComplete='username' onChange={(e)=>{setUserName(e.target.value)}} type="text" />
          </div>
        <button className='updateAccountButton' onClick={updateAccount}>UPDATE ACCOUNT</button>
        </label>
        <img style={{width:'40%',marginTop:'50%'}} src={idigitalcardlogo} alt="idigitalcardlogo" />
    </div>
  )
}
