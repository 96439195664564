import React from 'react'
import { useState,useContext } from 'react';
import userContext from '../userContext';
import trash from '../Icons/trash.png'


export default function ShowTheLinks(props) {
    const[addNewLinkShow,setAddNewLinkShow] = useState('none')
    const{LinksArr,setLinksArr,setAccountOrLinks,email} = useContext(userContext);
    const[title,setTitle] = useState(props.val.title);
    const[link,setLink] = useState(props.val.link);


    const addNewLink = ()=>
    {
        if(addNewLinkShow ==='none'){
            setAddNewLinkShow('flex');
        }
        else{
            setAddNewLinkShow('none');
        }
    }
    




    const updateLink = ()=>
    {
        props.val.title = title
        props.val.link = link
        setAddNewLinkShow('none');
    }   

    const deleteLink = ()=>
    {
        let newLinksArr = LinksArr.filter((item)=> item !== props.val ) 
        setLinksArr([...newLinksArr])

        fetch('/create-link',
            {
                headers:{
                    "Content-Type":"application/json",           
                },
                method:'post',
                body:JSON.stringify({
                    email:email.toLowerCase(),
                    Links:LinksArr
                })
            }).then((res)=>{return res.json()})
            .then((data) => {
                if(data === 'No Such Email Found')
                {
                    alert("Please Try Again");
                    props.setFlag(!props.flag)
                }
                else if(data === 'No fields were updated')
                {
                    alert("No fields were updated!")
                    props.setFlag(!props.flag)
                }
                else 
                {
                    alert("Fields Deleted successfully!")
                    props.setFlag(!props.flag)
                }
            })
            .catch((error)=>{return error})
  
        setAccountOrLinks('account')
        props.setAccountBackground('black')
        props.setAccountColor('white')
        props.setLinksBackground('white')
        props.setLinksColor('black')
    }

  return (
    <div  className='ShowTheLinksComponent'>
        <h1 onClick={addNewLink}>
            {props.val.title}
        </h1>
        <div style={{background:'white',display:addNewLinkShow,paddingTop:'5%'}}>
            <img src={trash} onClick={deleteLink} alt="deleteIcon" />
            <h2>Add a new link</h2>
            <label  id='title-input' >
              Title
              <input value={title} name='title' onChange={(e)=>{setTitle(e.target.value)}}   autoComplete='title'  type="text" />
            </label>
            <label  id='link-input' >
              Link
              <input value={link} name='link' onChange={(e)=>{setLink(e.target.value)}}   autoComplete='link'  type="text" />
            </label>
            <button onClick={updateLink} className='create-link-button'>UPDATE LINK</button> 
        </div>
    </div>
  )
}
