import React, { useContext, useState, useEffect } from 'react'
import userContext from '../userContext';
import ShowTheLinks from './ShowTheLinks';

export default function LinksUpdate(props) {
    const[addNewLinkShow,setAddNewLinkShow] = useState('none')
    const{LinksArr,setLinksArr,email} = useContext(userContext);
    const[title,setTitle] = useState('');
    const[link,setLink] = useState('');
    const[flag,setFlag] = useState(true)


    const addNewLink = ()=>
    {
        if(addNewLinkShow ==='none'){
            setAddNewLinkShow('flex');
        }else{
            setAddNewLinkShow('none');
        }
    }

    

    const creatLink = ()=>
    {
        if(title === '' || link === '')
        {
            alert("You need to fill the title & links fields ");
        }
        else
        {
            setLinksArr([...LinksArr,
            {
                title:title , link:link
            }
            ])
            setAddNewLinkShow('none');
            setTitle('');
            setLink('');
        }
    }

    const updateAccount = ()=>
    {
        fetch('/create-link',
        {
            headers:{
                "Content-Type":"application/json",           
            },
            method:'post',
            body:JSON.stringify({
                email:email.toLowerCase(),
                Links:LinksArr
            })
        }).then((res)=>{return res.json()})
        .then((data) => {
            if(data === 'No Such Email Found')
            {
                alert("Please Try Again");
                setFlag(!flag)
            }
            else if(data === 'No fields were updated')
            {
                alert("No fields were updated!")
                setFlag(!flag)
            }
            else 
            {
                alert("Fields updated successfully!")
                setFlag(!flag)
            }
        })
        .catch((error)=>{return error})

    }

    const showTheLinks = ()=>
    {
        return LinksArr.map((val,id)=>
        {
            return <ShowTheLinks flag={flag} setFlag={setFlag}
            setAccountBackground={props.setAccountBackground} setAccountColor={props.setAccountColor} 
            setLinksBackground={props.setLinksBackground} setLinksColor={props.setLinksColor}
             key={id} val={val} id={id}/>
        })
    }




  return (
    <div className='add-new-link-container'>
        {showTheLinks()}
        <div style={{display:addNewLinkShow}}>
            <h2>Add a new link</h2>
            <label  id='title-input' >
              Title
              <input value={title} name='title' onChange={(e)=>{setTitle(e.target.value)}}   autoComplete='title'  type="text" />
            </label>
            <label  id='link-input' >
              Link
              <input value={link} name='link' onChange={(e)=>{setLink(e.target.value)}}   autoComplete='link'  type="text" />
            </label>
            <button onClick={creatLink} className='create-link-button'>CREATE LINK</button> 
        </div>
        <button onClick={addNewLink} className='add-new-link-button'>+ Add new Link</button>
        <button className='RegisterButton' style={{width:'50%',marginTop:'8%'}} onClick={updateAccount}>Update Profile</button>

    </div>
  )
}
