import React, { useContext,useState } from 'react'
import idigitalcardlogo from '../images/idigitalcard.png'
import userContext from '../userContext'
import { useNavigate } from 'react-router-dom'
import DropDown from '../Components/DropDown'
import AccountEditBody from '../Components/AccountEditBody'
import LinksUpdate from '../Components/LinksUpdate'

export default function ProfileEdit() {

    const[accountBackground, setAccountBackground] = useState('black');
    const[accountColor, setAccountColor] = useState('white');
    const[linksBackground, setLinksBackground] = useState('white');
    const[linksColor, setLinksColor] = useState('black');
    const{accountOrLinks,setAccountOrLinks,setDropdown,setBio} = useContext(userContext)
    const{setName,setEmail,setPassword,setLinksArr,setPicture,
     } = useContext(userContext);
    const{setUserName} = useContext(userContext);
    const nav = useNavigate()

    const accountOrLinksButton = ()=>
    {
      if(accountOrLinks === 'account')
      {
        setAccountOrLinks('links');
        setLinksBackground('black');
        setLinksColor('white');
        setAccountBackground('white');
        setAccountColor('black');
      }
      else{
          setAccountOrLinks('account');
        setLinksBackground('white');
        setLinksColor('black');
        setAccountBackground('black');
        setAccountColor('white');
      }
    }

  const accountOrLinksShow = ()=>
  {
    if(accountOrLinks === 'account')
    {
      return <AccountEditBody/>
    }else
    {
      return <LinksUpdate 
      setAccountBackground={setAccountBackground} setAccountColor={setAccountColor} 
      setLinksBackground={setLinksBackground} setLinksColor={setLinksColor}/>
    }
  }



  const logout = ()=>
  {
    setName(null);
    setEmail(null);
    setPassword(null);
    setUserName(null);
    setLinksArr(null);
    setBio(null);
    setPicture(null);
    setDropdown('none');
    nav('/');
  }
  return (
    <div className='edit-profile-container'>
        <header className='edit-profile-container-header'>
            <img src={idigitalcardlogo} alt="idigitalcardlogo" />
            <DropDown logout={logout}/>
        </header>
        <div  className='edit-profile-form'>
        <div className='edit-profile-form-buttons'>
            <button onClick={accountOrLinksButton} style={{backgroundColor:accountBackground,color:accountColor,borderTopLeftRadius:'8px',borderBottomLeftRadius:'8px'}}>Account</button>
            <button onClick={accountOrLinksButton} style={{backgroundColor:linksBackground,color:linksColor,borderTopRightRadius:'8px',borderBottomRightRadius:'8px'}}>Links</button>
          </div>
        {accountOrLinksShow()}
        </div>
    </div>
  )
}
