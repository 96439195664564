import React, { useContext, useState } from 'react'
import idigitalcardlogo from '../images/idigitalcard.png'
import userContext from '../userContext';
import closedeye from '../images/closeEye.png'
import openeye from '../images/openeye.png'
import { useNavigate } from 'react-router-dom';
import instagramIcon from '../Icons/instablack.png'
import facebookIcon from '../Icons/facebookBlack.png'
import linkedinIcon from '../Icons/linkedin.png'
import websiteIcon from '../Icons/website.png'
import whatsappIcon from '../Icons/whatsappblack.png'
import tiktokIcon from '../Icons/tiktokblack.png'
import twitterIcon from '../Icons/twitter.png'
import snapchatIcon from '../Icons/snapchat.png'
import youtubeIcon from '../Icons/youtube.png'


export default function LogInRegister() {
  const[LogInRegister, setLogInRegister] = useState('register');
  const[LogInBackground, setLogInBackground] = useState('white');
  const[LogInColor, setLogInColor] = useState('black');
  const[RegisterBackground, setRegisterBackground] = useState('black');
  const[RegisterColor, setRegisterColor] = useState('white');
  const{name,setName,email,setEmail,password,setPassword,LinksArr,setLinksArr,bio,setBio,
    instagram ,setInstagram
    ,facebook ,setFacebook 
    ,linkedin ,setLinkedin 
    ,website  ,setWebsite  
    ,whatsapp ,setwhatsapp 
    ,twitter  ,setTwitter  
    ,snapchat ,setSnapchat 
    ,youtube  ,setYoutube  
    ,tiktok   ,setTiktok,
    picture,setPicture,
    setFile
  } = useContext(userContext);
  const{setUserName,setSocialMediaArr} = useContext(userContext);
  const[showAndHidePassword,setshowAndHidePassword] = useState('password');
  const nav = useNavigate()

  const LogInRegisterButtons = ()=>
  {
    if(LogInRegister === 'register')
    {
      setLogInRegister('login');
      setLogInBackground('black');
      setLogInColor('white');
      setRegisterBackground('white');
      setRegisterColor('black');
      setPassword('')
    }
    else{
      setLogInRegister('register');
      setLogInBackground('white');
      setLogInColor('black');
      setRegisterBackground('black');
      setRegisterColor('white');
    }
  }

  const showAndHidePasswordFunction = ()=>
  {
    if(showAndHidePassword === 'password')
    {
      setshowAndHidePassword('text');
    }
    else
    {
      setshowAndHidePassword('password');
    }
  }

  const eyeFunction = ()=>
  {
    if(showAndHidePassword === 'password')
    {
      return <img onClick={showAndHidePasswordFunction} style={{width:'7%',alignSelf:'end'}} src={openeye} alt="openeye" />
    }
    else
    {
      return <img onClick={showAndHidePasswordFunction} src={closedeye} style={{width:'7%',alignSelf:'end'}} alt="closedeye" />
    }
  }

// register button function
  const RegisterButton = ()=>
  {
    if(name.length < 2)
    {
      alert('name must be at least two characters long!');
    }
    else if(email.indexOf('@') === -1)
    {
      alert('Please check your email')
    }
    else if(password.length < 6)
    {
      alert('password must be at least six characters long!')
    }
    else
    {
      fetch('/register',{
        headers:{
            "Content-Type":"application/json",           
        },
        method:'post',
        body:JSON.stringify({
            name:name.toLowerCase(),
            email:email.toLowerCase(),
            password:password,
            username:'',
            bio:'Hello I\'m ' + name,
            instagram
            ,facebook
            ,linkedin
            ,website 
            ,whatsapp
            ,twitter 
            ,snapchat
            ,youtube 
            ,tiktok 
            ,picture,
            Links:LinksArr
        })
    })
    .then((res)=>{return res.json()})
    .then((data)=>
    {
      if(data === 'Registered Successfully')
      {
        const min = 9;
        const max = 99;
        const suffix = Math.floor(Math.random() * (max - min + 1)) + min;
        const nameForUserName = name.replace(' ','')
        setUserName(nameForUserName+suffix);
        nav('/user-choose')
      }
      else if(data === 'Register Failed')
      {
        alert('Register Failed please try again!')
      }
      else if(data === 'Email is already registered.')
      {
        alert('Email is already registered. Please login instead of registering.');        
      }
    }).catch((e)=>{return e})

    }
  }
// Log in function //
  const LoginButton = ()=>
  {
    fetch('/login-user',{
      headers:{
          "Content-Type":"application/json",           
      },
      method:'post',
      body:JSON.stringify({
          email:email.toLowerCase(),
          password:password
      })
  })
  .then((res)=>{return res.json()})
  .then((data)=>
  {
    if(data === 'Invalid Email or Password')
    {
      alert('Invalid Email or Password, Register Now!')
    }
    else
    {
      setName(data.name);
      setEmail(data.email);
      setPassword(data.password);
      setUserName(data.username);
      setBio(data.bio);
      setInstagram(data.instagram)
      setFacebook(data.facebook) 
      setLinkedin(data.linkedin) 
      setWebsite(data.website)  
      setwhatsapp(data.whatsapp)    
      setTwitter(data.twitter)          
      setSnapchat(data.snapchat)      
      setYoutube(data.youtube)       
      setTiktok(data.tiktok) 
      setPicture(data.picture) 
      setFile(data.picture)
      setSocialMediaArr([
        {type : 'instagram' ,link : data.instagram ,icon : instagramIcon},
        {type :'facebook', link :   data.facebook     ,icon : facebookIcon},
        {type : 'linkedin', link :  data.linkedin    ,icon : linkedinIcon},
        {type : 'website' , link:   data.website      ,icon : websiteIcon},
        {type:'whatsapp' ,link :    data.whatsapp      ,icon : whatsappIcon},
        {type: 'twitter' ,link:     data.twitter       ,icon : twitterIcon},
        {type : 'snapchat' ,link :  data.snapchat   ,icon : snapchatIcon},
        {type : 'youtube' , link :  data.youtube    ,icon : youtubeIcon},
        {type :'tiktok' , link :    data.tiktok       ,icon : tiktokIcon}
        ])    
      setLinksArr(data.Links);
      nav('/profile')
    }
  }).catch((e)=>{return e})

  }

  const LogInRegisterInputs = ()=>
  {
    if(LogInRegister === 'register')
    {
      return <div className='LogInRegisterInputsDiv'>
        <h1>
          Create a new account
        </h1>
        <label  id='name-input' >
          Your name
          <input name='name' value={name} autoComplete='name' onChange={(e)=>{setName(e.target.value)}} type="text" />
        </label>
        <label id='email-input' >
          Your email address
          <input name='email' value={email} autoComplete='email' onChange={(e)=>{setEmail(e.target.value)}} type="text" />
        </label>
        <label id='password-input' >
          Password
          <input name='password' value={password} autoComplete='password' onChange={(e)=>{setPassword(e.target.value)}} type={showAndHidePassword} />
          {eyeFunction()}
        </label>
        <button className='RegisterButton' onClick={RegisterButton}>Register</button>

      </div>
    }else
    {
      return <div className='LogInRegisterInputsDiv'>
        <h1>
          Login
        </h1>
        <label id='email-input' >
          Your email address
          <input name='email' value={email} autoComplete='email' onChange={(e)=>{setEmail(e.target.value)}} type="text" />
        </label>
        <label id='password-input' >
          Password
          <input name='password' value={password} autoComplete='password' onChange={(e)=>{setPassword(e.target.value)}} type={showAndHidePassword} />
          {eyeFunction()}
        <button className='ForgotYourPasswordButton' onClick={()=>{nav('/forgotpassword')}}>Forgot Your Password?</button>
        </label>
        <button className='RegisterButton' onClick={LoginButton}>LOGIN</button>

      </div>
    }
  }

  return (
    <div className='LogInRegisterContainer'>
        <div className='idigitalcardlogoDiv'>
          <img src={idigitalcardlogo} alt="idigitalcardlogo" />
        </div>
        <div className='LogInRegisterDiv'>
          <div className='LogInRegister'>
            <button onClick={LogInRegisterButtons} style={{backgroundColor:LogInBackground,color:LogInColor,borderTopLeftRadius:'8px',borderBottomLeftRadius:'8px'}}>Login</button>
            <button onClick={LogInRegisterButtons} style={{backgroundColor:RegisterBackground,color:RegisterColor,borderTopRightRadius:'8px',borderBottomRightRadius:'8px'}}>Register</button>
          </div>
          {LogInRegisterInputs()}
        </div>
        <img style={{width:'40%',marginTop:'30%'}} src={idigitalcardlogo} alt="idigitalcardlogo" />

    </div>
  )
}
