import React , {useContext,useEffect,useState} from 'react'
import userContext from '../userContext';
import closedeye from '../images/closeEye.png'
import openeye from '../images/openeye.png'
import idigitalcardlogo from '../images/idigitalcard.png'
import instagramIcon from '../Icons/instablack.png'
import facebookIcon from '../Icons/facebookBlack.png'
import linkedinIcon from '../Icons/linkedin.png'
import websiteIcon from '../Icons/website.png'
import whatsappIcon from '../Icons/whatsappblack.png'
import tiktokIcon from '../Icons/tiktokblack.png'
import twitterIcon from '../Icons/twitter.png'
import snapchatIcon from '../Icons/snapchat.png'
import youtubeIcon from '../Icons/youtube.png'



export default function AccountEditBody() {
  const[updateProfilePicture,setUpdateProfilePicture] = useState('none')
  const[updatePassword,setUpdatePassword] = useState('none')
  const[changeBio,setChangeBio] = useState('none')
  const[updateSocialMediaLinks,setUpdateSocialMediaLinks] = useState('none')


    const{name,setName,email,setEmail,password,setPassword,LinksArr,
        instagram ,setInstagram
        ,facebook ,setFacebook 
        ,linkedin ,setLinkedin 
        ,website  ,setWebsite  
        ,whatsapp ,setwhatsapp 
        ,twitter  ,setTwitter  
        ,snapchat ,setSnapchat 
        ,youtube  ,setYoutube  
        ,tiktok   ,setTiktok,
        picture,setPicture,
        file,setFile
,
    bio,setBio,userName,setUserName,setSocialMediaArr,socialMediaArr } = useContext(userContext);
    const[showAndHidePassword,setshowAndHidePassword] = useState('password');
    const[flag,setFlag] = useState(true)




    const eyeFunction = ()=>
    {
      if(showAndHidePassword === 'password')
      {
        return <img onClick={showAndHidePasswordFunction} style={{width:'7%',alignSelf:'end'}} src={openeye} alt="openeye" />
      }
      else
      {
        return <img onClick={showAndHidePasswordFunction} src={closedeye} style={{width:'7%',alignSelf:'end'}} alt="closedeye" />
      }
    }



    const showAndHidePasswordFunction = ()=>
    {
      if(showAndHidePassword === 'password')
      {
        setshowAndHidePassword('text');
      }
      else
      {
        setshowAndHidePassword('password');
      }
    }


    const updateProfilePictureFunc = ()=>
    {
      fetch('/update-profile-picture',
            {
                headers:{
                    "Content-Type":"application/json",           
                },
                method:'post',
                body:JSON.stringify({
                    email:email.toLowerCase(),
                    picture:file,
                })
            }).then((res)=>{return res.json()})
            .then((data) => {
                if(data === 'No Such Email Found')
                {
                    alert("Please Try Again");
                    setFlag(!flag)
                    setUpdateProfilePicture('none')
                }
                else if(data === 'No fields were updated')
                {
                    alert("No fields were updated!")
                    setFlag(!flag)
                    setUpdateProfilePicture('none')

                }
                else 
                {
                    alert("Profile Picture updated successfully!")
                    setFlag(!flag)
                    setUpdateProfilePicture('none')

                }
            })
            .catch((error)=>{return error})
    }

    const updatePasswordFunc = ()=>
    {
      if(password.length < 6)
      {
          alert('password must be at least six characters long!')
      }
      else
      {
      fetch('/update-password',
      {
          headers:{
              "Content-Type":"application/json",           
          },
          method:'post',
          body:JSON.stringify({
              email:email.toLowerCase(),
              password:password,
          })
      }).then((res)=>{return res.json()})
      .then((data) => {
          if(data === 'No Such Email Found')
          {
              alert("Please Try Again");
              setFlag(!flag)
              setUpdatePassword('none')
          }
          else if(data === 'No fields were updated')
          {
              alert("No fields were updated!")
              setFlag(!flag)
              setUpdatePassword('none')

          }
          else 
          {
              alert("Password updated successfully!")
              setFlag(!flag)
              setUpdatePassword('none')

          }
      })
      .catch((error)=>{return error})
    }
    }

    const changeBioFunc = ()=>
    {
      fetch('/update-bio',
      {
          headers:{
              "Content-Type":"application/json",           
          },
          method:'post',
          body:JSON.stringify({
              email:email.toLowerCase(),
              bio:bio,

          })
      }).then((res)=>{return res.json()})
      .then((data) => {
          if(data === 'No Such Email Found')
          {
              alert("Please Try Again");
              setFlag(!flag)
              setChangeBio('none')
          }
          else if(data === 'No fields were updated')
          {
              alert("No fields were updated!")
              setFlag(!flag)
              setChangeBio('none')

          }
          else 
          {
              alert("Bio updated successfully!")
              setFlag(!flag)
              setChangeBio('none')

          }
      })
      .catch((error)=>{return error})
    }

    const updateSocialMediaLinksFunc = ()=>
    {
      fetch('/update-social-media',
            {
                headers:{
                    "Content-Type":"application/json",           
                },
                method:'post',
                body:JSON.stringify({
                    email:email.toLowerCase(),
                    instagram:instagram,
                    facebook:facebook,
                    linkedin:linkedin,
                    website:website ,
                    whatsapp:whatsapp,
                    twitter:twitter ,
                    snapchat:snapchat,
                    youtube :youtube,
                    tiktok :tiktok,
                })
            }).then((res)=>{return res.json()})
            .then((data) => {
                if(data === 'No Such Email Found')
                {
                    alert("Please Try Again");
                    setFlag(!flag)
                    setUpdateSocialMediaLinks('none')
                }
                else if(data === 'No fields were updated')
                {
                    alert("No fields were updated!")
                    setFlag(!flag)
                    setUpdateSocialMediaLinks('none')

                }
                else 
                {
                    alert("Social Media Links updated successfully!")
                    setFlag(!flag)
                    setUpdateSocialMediaLinks('none')

                }
            })
            .catch((error)=>{return error})
    }

 
  
    // const updateAccount = ()=>
    // {
    //     if(userName.length < 5)
    //     {
    //         alert("Username must be at least 5 characters long")
    //     }
    //     else if(password.length < 6)
    //     {
    //         alert('password must be at least six characters long!')
    //     }
    //     else
    //     {
    //         fetch('/update-profile',
    //         {
    //             headers:{
    //                 "Content-Type":"application/json",           
    //             },
    //             method:'post',
    //             body:JSON.stringify({
    //                 email:email.toLowerCase(),
    //                 password:password,
    //                 username:userName,
    //                 bio:bio,
    //                 instagram:instagram,
    //                 facebook:facebook,
    //                 linkedin:linkedin,
    //                 website:website ,
    //                 whatsapp:whatsapp,
    //                 twitter:twitter ,
    //                 snapchat:snapchat,
    //                 youtube :youtube,
    //                 tiktok :tiktok,
    //                 picture:file,
    //                 Links:LinksArr
    //             })
    //         }).then((res)=>{return res.json()})
    //         .then((data) => {
    //             if(data === 'No Such Email Found')
    //             {
    //                 alert("Please Try Again");
    //                 setFlag(!flag)
    //             }
    //             else if(data === 'No fields were updated')
    //             {
    //                 alert("No fields were updated!")
    //                 setFlag(!flag)
    //             }
    //             else 
    //             {
    //                 alert("Fields updated successfully!")
    //                 setFlag(!flag)
    //             }
    //         })
    //         .catch((error)=>{return error})
    //     }
    // }


    // Function to resize the image
  const resizeImage = (imageData) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageData;
      img.onload = () => {
        // Set the canvas dimensions to the desired size
        const maxWidth = 800; // Max width
        const maxHeight = 600; // Max height
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions
        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;
          if (width > height) {
            width = maxWidth;
            height = width / aspectRatio;
          } else {
            height = maxHeight;
            width = height * aspectRatio;
          }
        }

        // Create a canvas element
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw the image onto the canvas with the new dimensions
        ctx.drawImage(img, 0, 0, width, height);

        // Convert canvas content to base64 encoded JPEG with specified quality
        const compressedPicture = canvas.toDataURL('image/jpeg', 1); // Adjust quality as needed

        // Resolve the promise with the resized image data
        resolve(compressedPicture);
      };
    });
  };




    useEffect(()=>{
        fetch('/get-users-profile',
        {
            headers:{
                "Content-Type":"application/json",           
            },
            method:'post',
            body:JSON.stringify({
                email:email.toLowerCase(),
            })
        }).then((res)=>{return res.json()})
        .then((data)=>{
            if(data !== 'User not found')
            {
                setName(data.name);
                setEmail(data.email);
                setUserName(data.username);
                setBio(data.bio);
                setInstagram(data.instagram);
                setFacebook(data.facebook);
                setLinkedin(data.linkedin);
                setWebsite(data.website);
                setwhatsapp(data.whatsapp);
                setTiktok(data.tiktok);
                setTwitter(data.twitter);
                setSnapchat(data.snapchat);
                setYoutube(data.youtube);
                setPassword(data.password);
                if (data.picture) {
                    resizeImage(data.picture).then((resizedImage) => {
                      setPicture(resizedImage);
                      setFile(resizedImage)
                    });
                  }
                  setSocialMediaArr([
                    {type : 'instagram' ,link : data.instagram ,icon : instagramIcon},
                    {type :'facebook', link :   data.facebook     ,icon : facebookIcon},
                    {type : 'linkedin', link :  data.linkedin    ,icon : linkedinIcon},
                    {type : 'website' , link:   data.website      ,icon : websiteIcon},
                    {type:'whatsapp' ,link :    data.whatsapp      ,icon : whatsappIcon},
                    {type: 'twitter' ,link:     data.twitter       ,icon : twitterIcon},
                    {type : 'snapchat' ,link :  data.snapchat   ,icon : snapchatIcon},
                    {type : 'youtube' , link :  data.youtube    ,icon : youtubeIcon},
                    {type :'tiktok' , link :    data.tiktok       ,icon : tiktokIcon}
                    ])
            }
        })
        .catch((err)=>console.log(err))
    },[flag])

    const handleFileInputChange = (e) => {
      setUpdateProfilePicture('flex')
        const myfile = e.target.files[0];
        if (myfile) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setPicture(reader.result)
            // Create a new Image object
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
              // Create a canvas element
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
    
              // Set the canvas dimensions to the desired size
              const maxWidth = 800; // Max width
              const maxHeight = 600; // Max height
              let width = img.width;
              let height = img.height;
    
              // Calculate new dimensions
              if (width > maxWidth || height > maxHeight) {
                const aspectRatio = width / height;
                if (width > height) {
                  width = maxWidth;
                  height = width / aspectRatio;
                } else {
                  height = maxHeight;
                  width = height * aspectRatio;
                }
              }
    
              // Set canvas dimensions
              canvas.width = width;
              canvas.height = height;
    
              // Draw the image onto the canvas with the new dimensions
              ctx.drawImage(img, 0, 0, width, height);
    
              // Convert canvas content to base64 encoded JPEG with specified quality
              const compressedPicture = canvas.toDataURL('image/jpeg', 0.7); // Adjust quality as needed
    
              // Set the compressed picture
              setFile(compressedPicture);
            };
          };
          reader.readAsDataURL(myfile);
        }
      };

    const imageShow = ()=>
    {
        if(picture === null)
        {
            return <img src={idigitalcardlogo} alt="profile" style={{alignSelf:'center' }}/>
        }
        else
        {
            return <img src={picture} alt="selected" style={{alignSelf:'center' }} />
        }
    }

  return (
    <div className='account-edit-body'>
        <label className='profile-picture-label' id="file-input">
          {imageShow()}
          <input name="file" autoComplete="file" style={{borderTopLeftRadius:'0px',borderTopRightRadius:'0px'}} onChange={handleFileInputChange} type="file" />
          <button onClick={updateProfilePictureFunc} className='change-button' style={{display:updateProfilePicture}}>Update Profile Picture</button>
        </label>
        <label  id='name-input' >
          Your name
          <input name='name' value={name} disabled autoComplete='name' onChange={(e)=>{setName(e.target.value)}} type="text" />
        </label>
        <label  id='bio-input' >
          Bio
          <textarea name="bio" value={bio} id="bio" onChange={(e)=>{setBio(e.target.value);setChangeBio('flex')}} cols="30" rows="5"></textarea>
          <button onClick={changeBioFunc} className='change-button' style={{display:changeBio}}>Change Bio</button>
        </label>
        <label  id='email-input' >
          Your email
          <input name='email' value={email} disabled autoComplete='email' onChange={(e)=>{setEmail(e.target.value)}} type="email" />
        </label>
        <label  id='username-input' >
          Your username
          <input name='username' disabled value={userName} autoComplete='username' onChange={(e)=>{setUserName(e.target.value)}} type="text" />
        </label>
        <label  id='instagram-input' >
          Instagram profile
          <input name='Instagram' value={instagram} autoComplete='Instagram' onChange={(e)=>{setInstagram(e.target.value);setUpdateSocialMediaLinks('flex')}} type="text" />
        </label>
        <label  id='username-input' >
          Facebook profile
          <input name='username' value={facebook} autoComplete='username' onChange={(e)=>{setFacebook(e.target.value);setUpdateSocialMediaLinks('flex')}} type="text" />
        </label>
        <label  id='username-input' >
          Linkedin profile
          <input name='username' value={linkedin} autoComplete='username' onChange={(e)=>{setLinkedin(e.target.value);setUpdateSocialMediaLinks('flex')}} type="text" />
        </label>
        <label  id='username-input' >
          Website
          <input name='username' value={website} autoComplete='username' onChange={(e)=>{setWebsite(e.target.value);setUpdateSocialMediaLinks('flex')}} type="text" />
        </label>
        <label  id='username-input' >
          Whatsapp Number
          <input name='username' value={whatsapp} autoComplete='username' onChange={(e)=>{setwhatsapp(e.target.value);setUpdateSocialMediaLinks('flex')}} type="text" />
        </label>
        <label  id='username-input' >
          Tiktok profile
          <input name='username' value={tiktok} autoComplete='username' onChange={(e)=>{setTiktok(e.target.value);setUpdateSocialMediaLinks('flex')}} type="text" />
        </label>
        <label  id='username-input' >
          Twitter profile
          <input name='username' value={twitter} autoComplete='username' onChange={(e)=>{setTwitter(e.target.value);setUpdateSocialMediaLinks('flex')}} type="text" />
        </label>
        <label  id='username-input' >
          Snapchat profile
          <input name='username' value={snapchat} autoComplete='username' onChange={(e)=>{setSnapchat(e.target.value);setUpdateSocialMediaLinks('flex')}} type="text" />
        </label>
        <label  id='username-input' >
          Youtube channel
          <input name='username' value={youtube} autoComplete='username' onChange={(e)=>{setYoutube(e.target.value);setUpdateSocialMediaLinks('flex')}} type="text" />
        <button onClick={updateSocialMediaLinksFunc} className='change-button' style={{display:updateSocialMediaLinks}}>Update Social Media Links</button>
        </label>

        <label id='password-input' >
          Password
          <input name='password' value={password} autoComplete='password' onChange={(e)=>{setPassword(e.target.value);setUpdatePassword('flex')}} type={showAndHidePassword} />
          {eyeFunction()}
        <button onClick={updatePasswordFunc} className='change-button' style={{display:updatePassword}}>Update Password</button>
        </label>
      </div>
  )
}
