import React from 'react'
import idigitalcardlogo from '../images/idigitalcard.png'
import userContext from '../userContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import login from '../Icons/login.png'

export default function ForgotPassword() {
    const{email,setEmail,setResetPasswordCode} = useContext(userContext);
    const nav = useNavigate()


    const sendCode = ()=>
    {
        fetch('/sendCode',{
            headers:{
                "Content-Type":"application/json",           
            },
            method:'post',
            body:JSON.stringify({
                email:email.toLowerCase(),
            })
        })
        .then((res)=>{return res.json()})
        .then((data)=> {
            if(data === 'This Email Is Not Registered Yet!')
            {
                alert(data);
            }
            else
            {
                setResetPasswordCode(data);
                nav('/code-submit');
            }
        }).catch((error)=>{return  error})
    }



    return (
    <div className='forgot-password-container'> 
        <div className='idigitalcardlogoDiv'>
          <img src={idigitalcardlogo} alt="idigitalcardlogo" />
        </div>
        <h1>
            RESET YOUR PASSWORD
        </h1>
        <div  className='inputemailforgotpassworddiv'>
            <label  id='email' >
              Your email address
              <input name='email' value={email} autoComplete='email' onChange={(e)=>{setEmail(e.target.value)}} type="email" />
            </label>
        <button className='i-have-remembered-my-password-button' onClick={()=>{nav('/login')}}>I have remembered my password!</button>
        <button className='SENDCODEBUTTON' onClick={sendCode}>SEND CODE</button>
        </div>
    </div>
  )
}
